import React, {useState, useEffect} from 'react';
import {FormElement, Field} from '@progress/kendo-react-form';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import {Label} from '@progress/kendo-react-labels';

export const CustomFormSkeletonOperation = (props) => {

	

	const [selectedArea, setSelectedArea] = useState(null);
	const [collaboratorOptions, setCollaboratorOptions] = useState([]);
    const [selectedCollaborator, setSelectedCollaborator] = useState(null);
   
    useEffect(() => {
        if (props.valueGetter) {
            const initialAreaValue = props.valueGetter('Área');
            const initialCollaboratorValue = props.valueGetter('title');

            if (initialAreaValue) {
                setSelectedArea(areaOptions.find(area => area.value === initialAreaValue));
            }
            if (initialCollaboratorValue) {
                setSelectedCollaborator(initialCollaboratorValue);
            }
        }
    }, [props.valueGetter]);

	// Supongamos que estas son tus opciones iniciales
	const areaOptions = [
		{value: '1', text: 'Data Engineer'},
		{value: '2', text: 'Data Analytics'},
        {value: '3', text: 'Festivo'}
		// ... más áreas
	];

	// Opciones iniciales de colaboradores (ajustar según tus datos)
	const allCollaboratorOptions = [
		{area: '1', text: 'Jorge Andres Ibañez Huertas'},
		{area: '1', text: 'Goretty Aracelly J Camara Mendez'},
		{area: '1', text: 'Brayann Yefferson Ariel Quevedo Benito'},
		{area: '1', text: 'Hector Andres Saa Gallego'},
		{area: '1', text: 'Luisa Fernanda Jaramillo Gomez'},
		{area: '2', text: 'Kevin Esteban Castaño'},
		{area: '2', text: 'Alvaro Almendrales'},
		{area: '2', text: 'Daniela Ruiz Valencia'},
		{area: '2', text: 'Camilo Andres Cabrera Meneses'},
		{area: '3', text: 'Festivo'},
	];

	// Función para manejar el cambio en el Área
	const handleAreaChange = (event) => {
		const newValue = event.target.value;
		setSelectedArea(newValue);
		if (props.onChange) {
			props.onChange('Área', {value: newValue.value});
		}
	};

	// useEffect para actualizar las opciones de colaboradores basado en el área seleccionada
	useEffect(() => {
		if (selectedArea) {
			const filteredOptions = allCollaboratorOptions.filter(
				(option) => option.area === selectedArea.value
			);
			setCollaboratorOptions(filteredOptions);
		} else {
			setCollaboratorOptions([]);
		}
	}, [selectedArea]);

	// Función para manejar cambios en el DropDownList de colaboradores
	const handleCollaboratorChange = (event) => {
		if (props.onChange) {
			props.onChange('title', {value: event.target.value.text});
		}
	};

	return (
		<FormElement horizontal={true}>
			<div className="k-form-field">
				<Label>Área</Label>
				<div className="k-form-field-wrap">
					<DropDownList
						name="Área"
						data={areaOptions}
						textField="text"
						dataItemKey="value"
						onChange={handleAreaChange}
						value={selectedArea}
					/>
				</div>
			</div>
			<div className="k-form-field">
				<Label>Nombre del Colaborador</Label>
				<div className="k-form-field-wrap">
					<DropDownList
						name="title"
						data={collaboratorOptions}
						textField="text"
						dataItemKey="text"
						onChange={handleCollaboratorChange}
						disabled={!selectedArea}
					/>
				</div>
			</div>
		</FormElement>
	);
};
