import React, { useState, useEffect, StrictMode } from 'react';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import './Tablero.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import Checkbox from '@mui/material/Checkbox ';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux';
import { setDynamicInputsData } from '../../../../../redux/renewalsTreeReducer'
import { selectDynamicInputsData } from '../../../../../redux/renewalsTreeReducer'
import axios from 'axios';
import { Notification } from '@progress/kendo-react-notification';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '8px',
    margin: '5px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.3)', // Add box shadow here
}));

const Tablero = ({ currentStep, formDataGlobal, handleOptionChange, reset, randomImage, goToCardById, selectionHistory, navigateTo }) => {
    const [IVA, setIVA] = useState(false)
    const date = new Date();
    date.setUTCHours(date.getUTCHours() - 5);
    const dispatch = useDispatch();
    const dynamicInputsData = useSelector(selectDynamicInputsData);
    const [selectedCountryValue, setSelectedCounrtyValue] = useState(null)
    const [formData, setFormData] = useState({
        fechaGestion: date.toISOString().slice(0, 16).replace('T', ' '),
        tipoGestion: '',
        nit: '',
        contacto: '',
        cargo: '',
        telefono: '',
        pagos: [
            [0, false],
            [0, false],
            [0, false],
            [0, false],
        ],
        tipoGestion2: 'General',
        fechaCompromiso: new Date().toISOString().split('T')[0],
        fechaCompromisoExtendido: "",
        descuento: '1',
        cuotas: [
            [50, new Date().toISOString().split('T')[0]],
            [50, new Date().toISOString().split('T')[0]],
            [0, new Date().toISOString().split('T')[0]],
        ],
        terceraCuotaPorLider: true,
        aplicaNC: 'No',
        tipoNC: '',
        motivoNC: '',
        valorNC: 0,
        notas: '',
        idNotaCredito: '',
        estado: "Pendiente",
        totalPayment: 0,
    });

    const calculateExtendedDate = (days = null) => {
        let normalDate = formData.tipoGestion2 === 'Cuotas'
            ? formData.cuotas[0][1]
            : formData.fechaCompromiso

        let date = new Date(`${normalDate}T05:00:00.000Z`);
        if ([4, 5, 6, 0].includes(date.getDay())) {
            date.setDate(date.getDate() + 1);
        }

        date.setDate(date.getDate() + parseInt(days != null ? days : savedBoardConfig.daysToExtendedPromise));

        return date.toISOString().split('T')[0];
    }

    useEffect(() => {
        dispatch(setDynamicInputsData({ ...dynamicInputsData, tablero: formData }))
    }, [formData])

    const selectOptions = {
        tipoGestion: [
            'Renov. Telefónica',
            'Renov. Whatsapp',
            'Renov. Email',
        ],
        tipoGestion2: ['General', 'Descuento', 'Cuotas', 'Posible Perdida', 'Certificado Digital'],
        aplicaNC: ['Si', 'No'],
        tipoNC: ['', 'Modifica Contrato', 'No Modifica Contrato'],
        motivoNC: {
            '': [],
            'Modifica Contrato': [
                'Cambio de contador/personal',
                'Casa Matriz exige cambio',
                'Desiste de Modulo/Usuarios',
                'Empresa en liquidación',
                'Falta de Dinero le parece costoso',
                'Falta de funcionalidad',
                'Ilocalizado/No aprece',
                'Nunca pago primera cuota/Anulado',
                'Problemas de Servicio',
                'Refacturado/Cesion de Licencia',
                'Conversión a otro producto Siigo',
                'Usa otro serial',
                'Error digitacion / Comercial',
                'Refacturado',
                'Cambio de Vigencia',
                'Usa programa (no quiere pagar mantenimiento)',
            ],
            'No Modifica Contrato': [
                'Refacturado / Cambio fecha factura a',
                'Refacturado cambio de razón social',
                'Refacturado error en la factura',
                'Descuento',
                'Promoción referidos',
            ]
        },
    };

    const calculatePagoTotal = () => {
        let subTotal = formData.pagos.reduce((acc, curr) => {
            let value = parseFloat(curr[0].toString().split(".").join(""))
            let thisEl = value;
            acc = parseFloat(acc) + thisEl;
            return acc;
        }, 0);

        let ivaTotal = formData.pagos.reduce((acc, curr) => {
            let hasIVA = curr[1]
            let value = parseFloat(curr[0].toString().split(".").join(""))
            let thisEl = hasIVA ? value * (IVA / 100) : 0;
            acc = parseFloat(acc) + thisEl;
            return acc;
        }, 0);
        let payment = ''
        switch (formData.tipoGestion2) {
            case 'Cuotas':
                payment = (parseFloat(subTotal) + parseFloat(ivaTotal)) * parseFloat(formData.cuotas[0][0] / 100)
                break;
            case 'Descuento':
                payment = (subTotal * (1 - formData.descuento / 100)) + ivaTotal
                break;
            default:
                payment = subTotal + ivaTotal;
                break;
        }
        return payment;
    };

    const formatNumber = (number) => {
        number = number.toString()
        const regex = /(?<=\d)(?=(\d\d\d)+(?!\d))/g;
        return number.replace(regex, ".")
    }

    const [savedBoardConfig, setSavedBoardConfig] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getBoardConfig = () => {
        axios.get(`/api/siignalGetconfig`).then((res) => {
            setIVA(parseInt(res.data[0][capitalizeFirstLetter(selectedCountryValue)].iva))
        })
        axios.get("/api/siignalGetPaymentPromiseConfig").then(res => {
            let savedConfig = res.data[capitalizeFirstLetter(selectedCountryValue)]
            let today = new Date();
            today.setDate(today.getDate() + parseInt(savedConfig.daysToExtendedPromise));
            setFormData({
                ...formData,
                "fechaCompromisoExtendido": calculateExtendedDate(savedConfig.daysToExtendedPromise)
            })
            setSavedBoardConfig(savedConfig);
            setIsLoading(false)
        }).catch(err => {
            console.log(err)
            alert("Error al guardar configuración")
        })
    }

    const calculateCuotaPayment = (cuota) => {
        let subTotal = formData.pagos.reduce((acc, curr) => {
            let value = parseFloat(curr[0].toString().split(".").join(""))
            let thisEl = value;
            acc = parseFloat(acc) + thisEl;
            return acc;
        }, 0);

        let ivaTotal = formData.pagos.reduce((acc, curr) => {
            let hasIVA = curr[1]
            let value = parseFloat(curr[0].toString().split(".").join(""))
            let thisEl = hasIVA ? value * (IVA / 100) : 0;
            acc = parseFloat(acc) + thisEl;
            return acc;
        }, 0);
        
        let finalValue = (parseFloat(subTotal) + parseFloat(ivaTotal))
        let payment = ((finalValue * parseFloat(cuota / 100)).toFixed()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return payment;
    }

    const calculateMaxDateForPromise = () => {
        const availableDaysForPromise = parseInt(savedBoardConfig.daysToPromise);
        let today = new Date();
        today.setDate(today.getDate() + availableDaysForPromise);
        return today.toISOString().split("T")[0];
    }
    useEffect(() => {
        let selectedCountry = localStorage.getItem("selectedCountryName")
        setSelectedCounrtyValue(selectedCountry)
    }, [])

    useEffect(() => {
        if (selectedCountryValue != null) {
            getBoardConfig();
        }
    }, [selectedCountryValue])

    const baseDiscount = dynamicInputsData.listSearchResutl?.DESC ? parseInt(dynamicInputsData.listSearchResutl.DESC.replace("%", "")) : 5;
    
    return (
        <StrictMode>
            {isLoading ? <>Cargando</> : <>
                <GridLayout
                    gap={{ rows: 10, cols: 10, }}>
                    {formData.tipoGestion2 === 'Descuento' ? (

                        <GridLayoutItem colSpan={18}>
                            <TextField
                                className="AlertText"
                                fullWidth
                                id="fullWidth"
                                sx={{
                                    border: 2,
                                    borderColor: 'red',
                                    color: 'red',
                                    textAlign: 'center',
                                }}
                                value={
                                    formData.tipoGestion2 === 'Descuento'
                                        ? 'Importante !!! Realiza Nota Credito de Descuento'
                                        : null
                                }
                                onChange={() => { }}
                            />

                        </GridLayoutItem>
                    ) : null}

                    <GridLayoutItem colSpan={6} col={1}>
                        <TextField
                            fullWidth
                            disabled
                            label="Fecha de gestion"
                            id="fullWidth"
                            value={formData.fechaGestion}
                            onChange={() => { }}
                        />
                    </GridLayoutItem>

                    <GridLayoutItem colSpan={6} col={7}>
                        <FormControl fullWidth>
                            <InputLabel id="tipoGestion" className="labelInputCustom">
                                Canal de atención
                            </InputLabel>
                            <Select
                                labelId="tipoGestion"
                                value={formData.tipoGestion}
                                onChange={(ev) => {
                                    setFormData({
                                        ...formData,
                                        tipoGestion: ev.target.value,
                                    });
                                }}
                            >
                                {selectOptions.tipoGestion.map((el, index) => {
                                    return (
                                        <MenuItem key={'tipoGestion1' + index} value={el}>
                                            {el}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </GridLayoutItem>
                    {(dynamicInputsData?.listSearchResutl?.Etiqueta
                         && <GridLayoutItem colSpan={12}>


                             <Notification key="info" type={{ style: 'info' }}>
                                 {dynamicInputsData?.listSearchResutl?.Etiqueta}
                             </Notification>
                         </GridLayoutItem>
                     )}
                    <GridLayoutItem colSpan={12} col={1}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Total antes de IVA</TableCell>
                                        <TableCell align="left">Aplica IVA</TableCell>
                                        <TableCell align="right">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {formData.pagos.map((pago, index) => {
                                        return (
                                            <TableRow key={'fila' + index}>
                                                <TableCell component="th" scope="row">
                                                    <TextField
                                                        fullWidth
                                                        id="fullWidth"
                                                        type="text"
                                                        inputProps={{
                                                            autoComplete: 'none',
                                                        }}
                                                        value={formData.pagos[index][0] || 0}
                                                        onChange={(ev) => {
                                                            let currentData = formData.pagos;
                                                            currentData = [...formData.pagos];
                                                            let value = ev.target.value
                                                            value = (value).replace(/^0+(?=\d{1,})/, "");
                                                            value = value.split(".").join("")
                                                            value = formatNumber(value)
                                                            currentData[index] = [(value === "") ? 0 : value, currentData[index][1]];
                                                            setFormData({
                                                                ...formData,
                                                                pagos: currentData,
                                                            });
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <input type='checkbox'
                                                        checked={formData.pagos[index][1]} // Set the initial checked state accordingly
                                                        onChange={(ev) => {
                                                            let currentData = formData.pagos;
                                                            currentData = [...formData.pagos];
                                                            currentData[index] = [currentData[index][0], ev.target.checked];
                                                            setFormData({
                                                                ...formData,
                                                                pagos: currentData,
                                                            });
                                                        }}
                                                    />{' '}
                                                    Aplica Iva
                                                </TableCell>
                                                <TableCell align="right">
                                                    $ {pago[1] ? (parseFloat(pago[0].toString().split(".").join(""))) * (IVA / 100) : (parseFloat(pago[0].toString().split(".").join("")))}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <b>
                                                ${' '}
                                                {formData.pagos.reduce((acc, curr) => {
                                                    acc = parseFloat(acc) + parseFloat(curr[0].toString().split(".").join(""));
                                                    return acc;
                                                }, 0)}
                                            </b>
                                        </TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right">
                                            <b>
                                                $
                                                {formData.pagos.reduce((acc, curr) => {
                                                    let hasIVA = curr[1]
                                                    let value = parseFloat(curr[0].toString().split(".").join(""))
                                                    let thisEl = hasIVA ? value * (IVA / 100) : value;
                                                    acc = parseFloat(acc) + thisEl;
                                                    return acc;
                                                }, 0)}
                                            </b>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </GridLayoutItem>
                    <GridLayoutItem colSpan={18} col={1}>
                        <Box>
                            <Grid container spacing={2} sx={{ pt: 2 }}>
                                <Grid item xs>
                                    <FormControl fullWidth>
                                        <InputLabel id="tipoGestion" className="labelInputCustom">
                                            Tipo de Gestion
                                        </InputLabel>
                                        <Select
                                            value={formData.tipoGestion2}
                                            onChange={(ev) => {
                                                setFormData({
                                                    ...formData,
                                                    tipoGestion2: ev.target.value,
                                                    descuento:
                                                        ev.target.value === 'Descuento'
                                                            ? formData.descuento
                                                            : 0,
                                                });
                                            }}
                                        >
                                            {selectOptions.tipoGestion2.map((el, index) => {
                                                return (
                                                    <MenuItem key={'tipoGestion2' + index} value={el}>
                                                        {el}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {formData.tipoGestion2 === 'Descuento' ? (
                                    <>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <InputLabel id="tipoGestion">% Descuento</InputLabel>
                                                <Select
                                                    value={formData.descuento}
                                                    onChange={(ev) => {
                                                        setFormData({
                                                            ...formData,
                                                            descuento: ev.target.value,
                                                        });
                                                    }}
                                                >
                                                    {Array.from({ length: baseDiscount }, (_, index) => {
                                                        const el = index + 1;
                                                        return (
                                                            <MenuItem key={`descuento${index}`} value={el}>
                                                                {el}%
                                                            </MenuItem>
                                                        );
                                                    })}

                                                    {dynamicInputsData.listSearchResutl && (
                                                        Array.from({ length: 5 }, (_, index) => (
                                                            <MenuItem key={`descuentoLider${index}`} value={baseDiscount + index + 1}>
                                                                {baseDiscount}% + {index + 1}% Aprov Lider
                                                            </MenuItem>
                                                        ))
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs>
                                            <TextField
                                                fullWidth
                                                disabled
                                                label="Valor Descontado"
                                                value={
                                                    (formData.pagos.reduce((acc, curr) => {
                                                        let value = parseFloat(curr[0].toString().split(".").join(""))
                                                        let thisEl = value
                                                        acc = parseFloat(acc) + thisEl;
                                                        return acc;
                                                    }, 0) *
                                                        (formData.descuento / 100)).toFixed(2)
                                                }
                                                onChange={(ev) => { }}
                                            />
                                        </Grid>
                                    </>
                                ) : null}

                                <Grid item xs>
                                    <label className='renewalsInputDateLabel'>
                                        <b>
                                            Fecha Compromiso
                                        </b>
                                    </label>
                                    <input
                                        className='renewalsInputDate'
                                        label="Fecha Compromiso"
                                        id="fullWidth"
                                        min={new Date().toISOString().split("T")[0]}
                                        type="date"
                                        max={calculateMaxDateForPromise()}
                                        value={formData.fechaCompromiso}
                                        onChange={(ev) => {
                                            setFormData({
                                                ...formData,
                                                fechaCompromiso: ev.target.value,
                                            });
                                        }}
                                    />
                                </Grid>

                                {formData.tipoGestion2 === 'Cuotas' ? (
                                    <Grid item xs={2}>
                                        <FormControlLabel
                                            control={
                                                <input type='checkbox'
                                                    checked={formData.terceraCuotaPorLider}
                                                    onChange={(ev) => {
                                                        setFormData({
                                                            ...formData,
                                                            terceraCuotaPorLider: ev.target.checked,
                                                        });
                                                    }}
                                                />
                                            }
                                            label="3ra Cuota Aprobada por Lider"
                                        />
                                    </Grid>
                                ) : null}

                                {formData.tipoGestion2 === 'Cuotas' ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} sx={{ pt: 2 }}>
                                                {/* CUOTA1 */}
                                                <Grid item xs>
                                                    <span>Cuota 1</span>
                                                    <TextField
                                                        sx={{ mb: 2 }}
                                                        fullWidth
                                                        label="Cuota"
                                                        id="fullWidth"
                                                        inputProps={{
                                                            autoComplete: 'none',
                                                        }}
                                                        value={formData.cuotas[0][0]}
                                                        onBlur={(ev) => {
                                                            let valor = ev.target.value;
                                                            if (!isNaN(valor) && parseFloat(valor) >= 40) {
                                                                setFormData({
                                                                    ...formData,
                                                                    fechaCompromiso: valor,
                                                                });
                                                            } else {
                                                                alert(
                                                                    'El valor de la cuota no debe ser menor al 40%'
                                                                );
                                                                let current = JSON.parse(JSON.stringify([...formData.cuotas]));
                                                                current[0][0] = 40;
                                                                current[1][0] = 60;
                                                                setFormData({
                                                                    ...formData,
                                                                    cuotas: current,
                                                                });
                                                            }
                                                        }}
                                                        onChange={(ev) => {
                                                            let current = JSON.parse(JSON.stringify([...formData.cuotas]));
                                                            current[0][0] = ev.target.value;
                                                            current[1][0] = 100 - ev.target.value;
                                                            setFormData({
                                                                ...formData,
                                                                cuotas: current,
                                                            });
                                                        }}
                                                    />
                                                    <TextField
                                                        sx={{ mb: 2 }}
                                                        fullWidth
                                                        disabled
                                                        label="Monto"
                                                        id="fullWidth"
                                                        inputProps={{
                                                            autoComplete: 'none',
                                                        }}
                                                        value={
                                                            calculateCuotaPayment(formData.cuotas[0][0])
                                                        }
                                                        onChange={(ev) => { }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Fecha"
                                                        id="fullWidth"
                                                        inputProps={{
                                                            autoComplete: 'none',
                                                        }}
                                                        type="date"
                                                        value={formData.cuotas[0][1]}
                                                        onChange={(ev) => {
                                                            setFormData((prevData) => {
                                                                const current = prevData.cuotas.map((innerArray, index) => {
                                                                    return index === 0 ? [...innerArray] : innerArray;
                                                                });
                                                                current[0][1] = ev.target.value;
                                                                return {
                                                                    ...prevData,
                                                                    cuotas: current,
                                                                };
                                                            });
                                                        }}
                                                    />
                                                </Grid>

                                                {/* CUOTA2 */}
                                                <Grid item xs>
                                                    <span>Cuota 2</span>
                                                    <TextField
                                                        sx={{ mb: 2 }}
                                                        fullWidth
                                                        disabled
                                                        label="Cuota"
                                                        id="fullWidth"
                                                        inputProps={{
                                                            autoComplete: 'none',
                                                        }}
                                                        value={formData.cuotas[1][0]}
                                                        onChange={(ev) => { }}
                                                    />
                                                    <TextField
                                                        sx={{ mb: 2 }}
                                                        fullWidth
                                                        disabled
                                                        label="Monto"
                                                        id="fullWidth"
                                                        inputProps={{
                                                            autoComplete: 'none',
                                                        }}
                                                        value={calculateCuotaPayment(formData.cuotas[1][0])}
                                                        onChange={(ev) => { }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Fecha"
                                                        id="fullWidth"
                                                        type="date"
                                                        value={formData.cuotas[1][1]}
                                                        onChange={(ev) => {
                                                            setFormData((prevData) => {
                                                                const current = prevData.cuotas.map((innerArray, index) => {
                                                                    return index === 1 ? [...innerArray] : innerArray;
                                                                });
                                                                current[1][1] = ev.target.value;
                                                                return {
                                                                    ...prevData,
                                                                    cuotas: current,
                                                                };
                                                            });
                                                        }}
                                                    />
                                                </Grid>

                                                {/* CUOTA3 */}
                                                {formData.terceraCuotaPorLider ? (
                                                    <>
                                                        <Grid item xs>
                                                            <span>Cuota 3</span>
                                                            <TextField
                                                                sx={{ mb: 2 }}
                                                                fullWidth
                                                                label="Cuota"
                                                                id="fullWidth"
                                                                inputProps={{
                                                                    autoComplete: 'none',
                                                                }}
                                                                value={formData.cuotas[2][0]}
                                                                onChange={(ev) => {
                                                                    let current = JSON.parse(JSON.stringify([...formData.cuotas]));
                                                                    current[2][0] = ev.target.value;
                                                                    current[1][0] =
                                                                        100 - ev.target.value - current[0][0];
                                                                    setFormData({
                                                                        ...formData,
                                                                        cuotas: current,
                                                                    });
                                                                }}
                                                            />
                                                            <TextField
                                                                sx={{ mb: 2 }}
                                                                fullWidth
                                                                disabled
                                                                label="Monto"
                                                                id="fullWidth"
                                                                value={calculateCuotaPayment(formData.cuotas[2][0])}
                                                                onChange={(ev) => { }}
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                label="Fecha"
                                                                id="fullWidth"
                                                                type="date"
                                                                value={formData.cuotas[2][1]}
                                                                onChange={(ev) => {
                                                                    setFormData((prevData) => {
                                                                        const current = prevData.cuotas.map((innerArray, index) => {
                                                                            return index === 2 ? [...innerArray] : innerArray;
                                                                        });

                                                                        current[2][1] = ev.target.value;

                                                                        return {
                                                                            ...prevData,
                                                                            cuotas: current,
                                                                        };
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : null}
                            </Grid>
                        </Box>
                    </GridLayoutItem>
                    <GridLayoutItem colSpan={18} col={1}>
                        <h3 className="notaCreditoTitle">Nota Credito</h3>
                    </GridLayoutItem>
                    <GridLayoutItem colSpan={18} col={1}>
                        <Box>
                            <Grid container spacing={2} sx={{ pt: 2 }}>
                                <Grid item xs>
                                    <FormControl fullWidth>
                                        <InputLabel id="tipoGestion" className="labelInputCustom">
                                            Aplica NC
                                        </InputLabel>
                                        <Select
                                            value={formData.aplicaNC}
                                            onChange={(ev) => {
                                                setFormData({
                                                    ...formData,
                                                    aplicaNC: ev.target.value,
                                                });
                                            }}
                                        >
                                            {selectOptions.aplicaNC.map((el, index) => {
                                                return (
                                                    <MenuItem key={'aplicaNC' + index} value={el}>
                                                        {el}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs>
                                    <FormControl fullWidth>
                                        <InputLabel id="tipoGestion" className="labelInputCustom">
                                            Tipo
                                        </InputLabel>
                                        <Select
                                            value={formData.tipoNC}
                                            onChange={(ev) => {
                                                setFormData({
                                                    ...formData,
                                                    tipoNC: ev.target.value,
                                                });
                                            }}
                                        >
                                            {selectOptions.tipoNC.map((el, index) => {
                                                return (
                                                    <MenuItem key={'tipoNC' + index} value={el}>
                                                        {el}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs>
                                    <FormControl fullWidth>
                                        <InputLabel id="tipoGestion" className="labelInputCustom">
                                            Motivo
                                        </InputLabel>
                                        <Select
                                            value={formData.motivoNC}
                                            onChange={(ev) => {
                                                setFormData({
                                                    ...formData,
                                                    motivoNC: ev.target.value,
                                                });
                                            }}
                                        >
                                            {selectOptions.motivoNC[formData.tipoNC].map((el, index) => {
                                                return (
                                                    <MenuItem key={'motivoNC' + index} value={el}>
                                                        {el}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        label="Valor"
                                        id="fullWidth"
                                        valor={formData.valorNC}
                                        onChange={(ev) => {
                                            setFormData({
                                                ...formData,
                                                valorNC: ev.target.value,
                                            });
                                        }}
                                    />
                                </Grid>
                                {
                                    savedBoardConfig.ncAvailable ? <>
                                        <Grid item xs>
                                            <TextField
                                                fullWidth
                                                label="ID Nota Credito"
                                                id="fullWidth"
                                                valor={formData.idNotaCredito}
                                                onChange={(ev) => {
                                                    setFormData({
                                                        ...formData,
                                                        idNotaCredito: ev.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </> : null
                                }
                            </Grid>
                        </Box>
                    </GridLayoutItem>


                    <GridLayoutItem colSpan={6} rowSpan={2} col={13} row={2}>
                        <GridLayout>
                            <Item>
                                <TextField
                                    fullWidth
                                    disabled
                                    label="Compromiso de pago"
                                    id="fullWidth"
                                    value={calculatePagoTotal()}
                                    onChange={(ev) => {
                                    }}
                                />
                            </Item>
                        </GridLayout>
                        <GridLayout>
                            <Item>
                                <TextField
                                    fullWidth
                                    disabled
                                    label="Fecha Extendida"
                                    id="fullWidth"
                                    value={calculateExtendedDate()}
                                />
                            </Item>
                        </GridLayout>
                        <GridLayout>
                            <Item>
                                <textarea
                                    placeholder="Notas adicionales"
                                    value={formData.notas}
                                    onChange={(ev) => {
                                        setFormData({
                                            ...formData,
                                            notas: ev.target.value,
                                        });
                                    }}
                                />
                            </Item>
                        </GridLayout>
                    </GridLayoutItem>
                </GridLayout>
            </>
            }
        </StrictMode>
    );
};

export default Tablero;